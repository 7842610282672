<template lang="">
  <div>
    <form class="card p-2" @submit.prevent="updateUser">
      <h2>Edition de l'utilisateur</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="nom">Nom:</label>
            <input
              v-model="user.firstName"
              type="text"
              id="nom"
              class="form-control"
              placeholder="Nom..."
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="prenom">Prenom:</label>
            <input
              v-model="user.lastName"
              type="text"
              id="prenom"
              class="form-control"
              placeholder="Prenom..."
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="ville">Ville:</label>
            <input
              v-model="user.city"
              id="ville"
              type="text"
              class="form-control"
              placeholder="Ville..."
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="country">Pays:</label>
            <input
              v-model="user.country"
              type="text"
              id="country"
              class="form-control"
              placeholder="Pays..."
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="email">Email:</label>
            <input
              v-model="user.email"
              type="text"
              id="email"
              class="form-control"
              placeholder="Email..."
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group  mt-2">
            <label for="tel">Tel:</label>
            <input
              v-model="user.phone"
              id="tel"
              type="text"
              class="form-control"
              placeholder="tel..."
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group  mt-2 col-md-6">
            <label for="group">Group:</label>
            <select
              v-model="user.groups"
              id="group"
              type="text"
              class="form-control form-select"
              placeholder="tel..."
            >
              <option v-for="g in listGroups" :key="g.name" :value="g.name">{{
                g.name
              }}</option>
            </select>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-2">Appliquer</button>
      </div>
    </form>
    <div class="card p-2" v-if="user.groups === 'teacher'">
      <form class="row" @submit.prevent="setTeacherProfile">
        <h2>Profil de l'enseignant</h2>
        <div class="row">
          <div class="col-md-4 form-group mt-2">
            <label for="rate">Pourcentage:</label>
            <input
              id="rate"
              step="0.01"
              max="1"
              min="0"
              type="number"
              class="form-control"
              v-model="teacherProfile.rate"
              placeholder="Certification..."
            />
          </div>
          <div class="col-md-4 form-group mt-2">
            <label for="about">CNI/NIU:</label>
            <input
              id="about"
              class="form-control"
              v-model="teacherProfile.cniOrNiu"
              placeholder="A propos..."
            />
          </div>
          <div class="col-md-4 form-group mt-2">
            <label for="about">Entreprise</label>
            <input
              id="about"
              class="form-control"
              v-model="teacherProfile.entrepriseName"
              placeholder="A propos..."
            />
          </div>
          <button
            :disabled="
              teacherProfile.rate == 0 ||
                teacherProfile.cniOrNiu == '' ||
                teacherProfile.entrepriseName == ''
            "
            class="btn btn-primary mt-2"
            type="submit"
          >
            Changer
          </button>
        </div>
      </form>
    </div>
    <div class="card p-2">
      <div class="row">
        <form class="col-md-6" @submit.prevent="setPassword">
          <div class="form-group mt-2">
            <h2>Changer le mot de passe</h2>
            <label for="password">Mot de passe:</label>
            <input
              id="password"
              type="text"
              class="form-control"
              v-model="pwd"
              placeholder="Mot de passe..."
            />
            <button
              class="mt-2 btn btn-secondary"
              @click="generate"
              type="button"
            >
              Generer
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-primary ml-2 mt-2" type="submit">
              Changer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hasProfile: null,
      user: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        country: "",
        city: "",
        groups: "",
      },
      pwd: "",
      teacherProfile: {
        cniOrNiu: "",
        entrepriseName: "",
        rate: "",
      },
      listGroups: [],
    };
  },
  methods: {
    async loadUser() {
      await this.$http
        .get(`/auth/admin/user/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data;
          if (response.data.groups === "teacher") this.loadProfile();
        });
    },
    async loadProfile() {
      await this.$http
        .get(`/auth/admin/teacher-profile/${this.$route.params.id}`)
        .then((response) => {
          if (response.data != "" && response.data != null) {
            this.teacherProfile = response.data;
            this.hasProfile = true;
          } else this.hasProfile = false;
        });
    },
    async updateUser() {
      await this.$http
        .put(`/auth/admin/user/${this.user.id}`, this.user)
        .then(() => {
          this.loadUser();
          this.$store.state.teachers.updated = false;
          this.$store.state.allUsers.updated = false;
          this.$toast.success("success");
        })
        .catch(() => {
          this.$toast.error("Erreur lors de la sauvegarde!");
        });
    },
    async setPassword() {
      await this.$http
        .put(`/auth/admin/reset-user-password/${this.user.id}`, {
          password: this.pwd,
        })
        .then(() => {
          this.loadUser();
          this.$store.state.teachers.updated = false;
          this.$toast.success("success");
          this.pwd = "";
        })
        .catch(() => {
          this.$toast.error("Erreur lors de la sauvegarde!");
        });
    },
    generate() {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      this.pwd = result;
    },
    async setTeacherProfile() {
      if (this.hasProfile === true) {
        await this.$http
          .put(
            `/auth/admin/teacher-profile/${this.user.id}`,
            this.teacherProfile
          )
          .then(() => {
            this.loadProfile();
            this.$store.state.teachers.updated = false;
            this.$toast.success("success");
          })
          .catch(() => {
            this.$toast.error("Erreur lors de la sauvegarde!");
          });
      } else if (this.hasProfile === false) {
        await this.$http
          .post(`/auth/admin/create-teacher-profile`, {
            ...this.teacherProfile,
            user: this.user.id,
          })
          .then(() => {
            this.loadProfile();
            this.$store.state.teachers.updated = false;
            this.$toast.success("success");
          })
          .catch(() => {
            this.$toast.error("Erreur lors de la sauvegarde!");
          });
      }
    },
    async getGroups() {
      await this.$http.get("auth/admin/groups-list").then((response) => {
        this.listGroups = response.data;
      });
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadUser();
      this.getGroups();
    });
  },
};
</script>
<style lang=""></style>
